
.rhap_progress-section,.rhap_additional-controls{
    display: none;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
    display: none;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
    display: none;
}

.footer{
    
    bottom: 15px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    justify-content: space-around;
}

.rhap_container{
    background-color:rgb(59, 55, 54,2);
    width: 50%;
    display: inline-flex;
    
    
    
}
.rhap_controls-section {
    height: 40px;
}
.rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: #17C0EA ;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: rgb(23, 192, 234);
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(23, 192, 234 , 0.5) 0 0 3px;
    cursor: pointer;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #17C0EA;
    font-size: 35px;
    width: 35px;
    height: 35px;
}





.rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    -webkit-user-select: none;
    padding-right: 10px;
}


@media only screen and (max-width: 767px)  {
    .grande{
        display:none;
    }

    .footer-ch{
        bottom: 20px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        justify-content: space-around;
    }
    .center-player{
        display: flex;
        justify-content: center;
    }
    .center-ch{
        display: flex;
        justify-content: center;
       
    }

    .rhap_container {
        background-color: transparent;
        width: 50%;
        display: inline-flex;
    }

    .header-footer{
        justify-content: space-around;
        display: flex;
        /* margin-right: 15px; */
        margin-top: 20px;
        align-items: center;
    }
    .rhap_stacked .rhap_controls-section {
        margin-top: -5px;
    }
}

@media only screen and (min-width: 767px) {
    .chica{
        display:none;
       
    }
    
 

}
@media (orientation: landscape)and (max-width: 767px) {
    .footer-ch {
        bottom: 2px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        justify-content: space-around;
    }

    .header-footer{
        justify-content: space-around;
        display: flex;
        /* margin-right: 15px; */
        margin-top: 0px;
        align-items: center;
    }
    .tamanio-muni{
        width: 60px;
        height: 60px;
    }
}